/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2rem 0;
  background: rgba(0, 0, 0, 0.3);
}

.c-footer p {
  font-size: 1rem;
  font-weight: 400;
}
