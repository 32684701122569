/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 90%;
  width: 1200px;

  &--short {
    width: 1100px;
  }
}
