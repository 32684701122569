/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  margin-top: 1rem;
  width: 1400px;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include bp(medium) {
    margin-top: 3rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
  }

  @include bp(full) {
    max-width: 100%;
  }
}

.c_header__menu ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;

  @include bp(small) {
    flex-direction: row;
    margin-left: 90px;
  }

  @include bp(small) {
    margin-top: 1rem;
  }

  @include bp(medium) {
    margin-top: 0;
  }
}

.c_header__menu ul li {
  position: relative;
}

.ico {
  position: relative;

  margin-top: 2rem;

  @include bp(small) {
    margin-top: 0;
  }

  &:first-child {
    @include bp(small) {
      margin-right: 90px;
    }
  }
}

.ico:before {
  content: '';
  display: block;
  background-color: $color-orange;
  width: 47px;
  height: 47px;
  border-radius: 47px;
  background-image: url('../assets/images/phone.svg');
  background-repeat: no-repeat;
  background-position: center;

  position: absolute;
  left: -60px;
}

.ico-mail:before {
  background-image: url('../assets/images/mail.svg');
}
