/* ==========================================================================
   #PAGE
   ========================================================================== */

/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */

html {
  background: $color-bg;
  color: $color-text;
  font-family: $font-sans-serif;
  font-size:  0.75em; /* [1] */ /* 18px */
  line-height: 1.5; /* [1] */
  min-height: 100%; /* [3] */
  overflow-y: scroll; /* [2] */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  background-image: url('../assets/images/background-image.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;

  @include bp(medium) {
    font-size: 0.85rem; /* 14px */
  }

  @include bp(large) {
    font-size: 1rem; /* 15px */
  }

  @include bp(full) {
    font-size: 1.1rem; /* 16px */
  }
}

body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
