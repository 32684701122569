.main-headline {
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.3;
}

 @media(max-width: 615px) and (max-height: 550px) {.main-headline{ font-size: 0.9rem }}

@media(max-width: 360px) {
  .main-headline{ font-size: 1.4rem }
}

